body {
  background-color: #cfd4d4;
  color: #555357;
  font-family: 'Fira Sans Condensed', Helvetica, sans-serif;
}

/* Layout */
#header,
#header-hero {
  padding-left: 17rem;
}

#header .navbar {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}

#header .navbar-toggler {
  padding: 0rem .5rem;
}

#header .navbar-toggler:focus {
  box-shadow: none;
  outline: 0;
}

#sidebar {
  background-color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 17rem;
}

#footer {
  background: #5d5b5c;
  color: #ffffff;
  padding: 1.5rem;
  margin-left: 17rem;
}

#footer a {
  color: inherit;
}

#footer ul li a {
  text-decoration: none;
}

#footer ul li a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 1rem;
  margin-bottom: 0;
}

#main {
  padding-left: 17rem;
}

#main-menu {
  margin-top: 8vh;
  text-align: center; 
}

#main-menu ul li a {
  color: #fff;
  text-decoration: none;
}

#main-menu ul li a:hover,
#main-menu ul li a:focus {
  text-decoration: underline;
}

#main-menu-modal {
  background-image: url('../../../public/img/home.png');
  height: auto;
  min-height: 100%;
}

#main-menu-modal .navbar-toggler {
  color: #888;
}

#main-menu-modal .modal-content {
  background-color: transparent;
  color: #fff;  
}

#main-menu-modal .modal-header {
  border-bottom-color: transparent;
}

#main-menu-modal .modal-footer {
  border-top-color: transparent;
}

#hero {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#hero #main {
  min-height: calc(100vh - 212px);
  padding-top: 70px;
}

/* Layout responsive */
@media (max-width: 768px) {
  #header,
  #header-hero {
    padding-left: 0rem;
  }

  #header {
    background-color: #fff;
  }

  #footer {
    margin-left: 0;
  }

  #main {
    padding-left: 0;
  }
}

/* Components */
.searchbox {
  position: relative;
  text-align: right;
}

.searchbox input {
  display: none !important;
  height: 38px;
  line-height: 1;
  outline: 0;
  font-size: 1rem;
  border-radius: 25px !important;
  padding: 0 2.5rem 0 1rem;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #ffffff !important;
  color: #ffffff;
  text-align: left;
}

.searchbox input::placeholder {
  color: #ffffff !important;
}

.searchbox input:focus {
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.searchbox:hover {
  cursor: pointer;
  width: 100%;
}

.searchbox:hover input {
  display: block !important;
}

.searchbox svg {
  margin-right: 0.5rem;
}

.searchbox:hover svg {
  position: absolute;
  right: 0;
  top: 3px;
}

.table>:not(:first-child) {
  border-top: 1px solid #8f8f8f;
}
.btn-round {
  border-radius: 2rem;
}

.btn-purple {
  background-color: #ed018c;
  border-color: #ed018c;
  color: #ffffff;
}

.btn-purple:hover {
  background-color: #c22481;
  border-color: #c22481;
  color: #ffffff;
}

.input-group .form-control,
.input-group-transparent .btn {
  border-color: #ffffff;
}

.input-group-round .form-control {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  padding-left: 1rem;
}

.input-group-round .btn {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.form-control-transparent,
.form-control-transparent:focus {
  background-color: transparent;
}

.input-group-transparent .form-control {
  border-right-color: transparent;
  color: inherit;
}

.input-group-transparent .form-control:focus {
  box-shadow: none;
  outline: 0;
}

.input-group-transparent .btn {
  background-color: transparent;
  border-left-color: transparent;
  color: inherit;
}

.menu-toggler .navbar-toggler {
  color: #575654;
}

.menu-toggler-transparent .navbar-toggler {
  color: #fff;
}

.navbar.bg-light {
  background-color: #fff !important;
}

.avatar {
  background-size: cover;
}

.avatar.avatar-circle {
  border-radius: 50%;
}

.avatar.avatar-xs {
  height: 3rem;
  width: 3rem;
}

.avatar.avatar-sm {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar.avatar-md {
  height: 4rem;
  width: 4rem;
}

.avatar.avatar-lg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatar-xlg {
  height: 5rem;
  width: 5rem;
}

.divider {
  background-color: #575654;
  display: block;
  height: 1px;
  margin: 15px 0;
}

.autocomplete-city {
  color: #575654;
  font-size: 1.25rem;
}

.city-selector .input-group input {
  font-size: 1.2rem;
  font-weight: bold;
}

.city-selector .city-list {
  height: 300px;
  list-style: none;
  overflow-y: scroll;
  padding: 0;
}

.city-selector .city-list-wrapper {
  position: relative;
}

.city-selector .city-list-wrapper:after {
  content: '';
  pointer-events: none;
  position: absolute;
  bottom: 0;
  height: 40px;
  left: 0;
  z-index: 2;
  width: 100%;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); 
  background-image: -moz-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -ms-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.city-selector .city-list-wrapper.scroll-end:after {
  content: none;
}

.city-selector .city-list .btn {
  color: inherit;
  text-align: left;
  text-decoration: none;
}

.city-selector .city-list .btn:focus {
  box-shadow: none;
  outline: 0;
}

.city-selector .city-list li.active {
  background-color: rgba(207,212,212,0.5);
}

.accordion-cabildo .accordion-item.no-caret .accordion-button::after,
.accordion-cabildo .accordion-item.no-caret .accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-cabildo .accordion-item {
  background-color: transparent;
  border-color: transparent;
}

.accordion-cabildo .accordion-button {
  padding: 0.5rem 0;
}

.accordion-cabildo .accordion-button,
.accordion-cabildo .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  border-top: 4px solid #575654;
  color: #575654;
  font-size: 1.25rem;
  font-weight: bold;
}

.accordion-cabildo .accordion-button:not(.collapsed) {
  border-bottom: 1px solid #575654;
}

.accordion-cabildo .accordion-button::after {
  background-size: 2rem;
  height: 2rem;
  width: 2rem;
}

.accordion-cabildo .accordion-button::after {
  background-image: url('data:image/svg+xml,<svg stroke="%23575654" fill="%23575654" stroke-width="0" viewBox="0 0 24 24" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 17 7-8h-14z"></path></svg>');
}

.accordion-cabildo .accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml,<svg stroke="%23575654" fill="%23575654" stroke-width="0" viewBox="0 0 24 24" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 17 7-8h-14z"></path></svg>');
}

.accordion-cabildo .accordion-body {
  padding: 0.5rem 0;
}

.accordion-cabildo .faq-accordion-item .accordion-button,
.accordion-cabildo .faq-accordion-item .accordion-button:not(.collapsed) {
  border-top: 1px solid #575654;
}

.accordion-cabildo .faq-accordion-item:first-child .accordion-button {
  border-top-color: transparent;
}

.accordion-cabildo .accordion-item.faq-accordion-item .accordion-button {
  border-bottom-color: transparent;
}

.accordion-cabildo .accordion-item.faq-accordion-item .accordion-button::after {
  background-image: none;
}

.puerto-rico-map path {
  cursor: pointer;
}

.puerto-rico-map .svg-map__location {
  fill: #575654;
  outline: none;
  stroke: #8a8a8a;
}

.puerto-rico-map .svg-map__location.disabled {
  fill: #ababab;
  cursor: default;
  stroke: #707070;
}

.puerto-rico-map .svg-map__location[aria-checked=true] {
  fill: #ed018c;
  stroke: #ed018c;
}

.slick-slider.slider-cabildo .slick-arrow.slick-prev,
.slick-slider.slider-cabildo .slick-arrow.slick-next {
  color: #575654;
  font-size: 40px;
  height: 40px;
  width: 40px;
  z-index: 10;
}

.slick-slider.slider-cabildo .slick-arrow.slick-prev {
  left: -40px;
}

.slick-slider.slider-cabildo .slick-arrow.slick-prev::before,
.slick-slider.slider-cabildo .slick-arrow.slick-next::before {
  content: none;
}

.page-link {
  color: #555357;
}

.page-item.active .page-link {
  background-color: #5e5b5c;
  border-color: #5e5b5c;
}

/* Content */
.avatar {
  border: 2px solid #a4a3a3;
}

.avatar-ind {
  border-color: #222;
}

.avatar-pnp {
  border-color: #007BC1;
}

.avatar-pip {
  border-color: #6DBE4C;
}

.avatar-ppd {
  border-color: #E4375E;
}

.avatar-pd {
  border-color: #0FADC6;
}

.avatar-mvc {
  border-color: #e0a230;
}

.calendar-row,
.document-item {
  padding: 0.5rem 0;
}

.calendar-row + .calendar-row {
  border-top: 1px solid #8f8f8f;
}

.calendar-row > .row {
  cursor: pointer;
}

.document-item {
  color: #555357;
}

.document-item td,
.document-header-item th {
  border-bottom-color: #8f8f8f;
}

.document-header-item th {
  font-weight: normal;
}

.document-header-item {
  color: #ffffff;
}

.document-header-item .document-item-date,
.document-item .document-item-date,
.document-item .document-item-actions {
  white-space: nowrap;
  width: 1%;
}

.document-item .document-name {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 24px; /* fallback */
  max-height: 48px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.document-item .actions {
  color: #575654;
  text-align: right;
}

.document-item .actions a {
  color: inherit;
}

.document-filter {
  border-bottom: 1px solid #575654;
  padding-bottom: 0.5rem;
}

.document-filter select.form-select {
  background-color: #6c757d;
  color: #fff;
}

.document-type-list {
  margin-bottom: 0px;
}

.document-type-list .document-type-item .btn-link {
  border-radius: 0px;
  color: #555357;
  padding: 0.3rem 0 0.3rem 0;
  text-decoration: none;
}

.document-type-list .document-type-item .btn-link:focus {
  outline: none;
  box-shadow: none;
}

.document-type-list .document-type-item.document-type-item-active .btn-link {
  border-bottom: 3px solid #575654;
}

.document-type-list .document-type-item + .document-type-item {
  margin-left: 2.5rem;
}

.document-type-list-wrapper {
  position: relative;
}

@media (max-width: 768px) {
  .document-type-list {
    flex-wrap: inherit;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none; /*// IE 10+*/
    overflow: -moz-scrollbars-none; /*// Firefox*/
  }
  .document-type-list::-webkit-scrollbar {
    display: none; /*Safari and Chrome*/
  }
  .document-type-list > li {
    float: none;
    margin-bottom: 0px;
  }
  .document-type-list .document-type-item .btn-link {
    white-space: nowrap;
  }
  .document-type-list-wrapper::after {
    background-image: url('data:image/svg+xml,<svg stroke="%23575654" fill="%23575654" stroke-width="0" viewBox="0 0 24 24" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg>');
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    right: -15px;
    top: 0;
    bottom: 0;
    width: 22px;
  }
  .document-filter {
    flex-direction: column;
  }
  .document-filter > div + div {
    margin-top: 0.5rem;
  }
}

.event-row {
  display: flex;
  align-items: center;
}

.event-row .event-date {
  padding: 1rem;
}

.event-row .event-actions {
  padding: 1rem 2rem 1rem 0;
  width: 110px;
}

.event-row .event-actions a {
  color: inherit;
}

.event-row .event-date {
  border-right: 6px dotted #cccec9;
  width: 140px;
}

.event-row .event-date {
  text-align: center;
}

.event-row .event-body {
  flex-grow: 1;
  padding: 1rem 1.4rem;
}

.event-row .event-body p {
  margin-bottom: 0;
}

.event-row.odd {
  background-color: #ccc;
}

.event-row.odd .event-date {
  border-right-color: #f4f4f4;
}

.event-item {
  background-color: #fff;
  margin-bottom: 1rem;
}

.event-item .event-date h1 {
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1;
}

.event-item .event-date h1 span {
  display: block;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.event-item .event-body h4 {
  font-size: 1.3331rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 24px; /* fallback */
  max-height: 48px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media (max-width: 768px) {
  .event-row .event-date {
    width: 90px;
  }
  .event-row .event-body {
    padding: 1rem;
  }
  .event-item .event-body h4 {
    font-size: 1.2rem;
  }
  .event-row .event-actions {
    padding: 1rem 1rem 1rem 0;
    width: auto;
  }
  .event-item .event-date h1 {
    font-size: 2rem;
  }
  .event-item .event-date h1 span {
    font-size: 1.3rem;
  }
}

.modal-event .modal-header,
.modal-upcoming-events .modal-header {
  border-bottom: 5px dotted #cccec9;
}

.modal-event h5.modal-title,
.modal-upcoming-events h5.modal-title {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1;
  padding: 0 0.5rem;
  position: relative;
  text-align: center;
}

.modal-event h5.modal-title:after {
  border-right: 5px dotted #cccec9;
  bottom: -1rem;
  content: '';
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 5px;
}

.modal-event h5.modal-title span {
  display: block;
  font-size: 1.5rem;
}

.modal-event .modal-body h2 {
  font-weight: bold;
  margin-bottom: 2rem;
}

.modal-event .modal-body h2 span {
  display: block;
  font-size: 1rem;
}

/* Utils */
.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

/* Shimmer */
.shimmer {
  background-color: #b1b1b1;
  background-image: linear-gradient(
    to right,
    #b1b1b1 0%,
    #a1a1a1 20%,
    #b1b1b1 40%,
    #b1b1b1 100%
  );
  background-size: 99% 100%;
  background-repeat: no-repeat;
  animation: shimmer 1s linear 1ms infinite backwards;
}

.shimmer-line {
  height: 15px;
}

.shimmer-round {
  border-radius: 0.75rem;
}

.shimmer-circle {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

/* Theme */
.dropdown-item.active,
.dropdown-item:active {
  background-color: #5d5b5c;
}
#header {
  background-color: #5d5b5c;
  color: #ffffff;
}

#header-hero,
#header-hero .searchbox {
  color: #ffffff;
}

#header .navbar-toggler,
#header-hero .navbar-toggler {
  color: inherit;
}

/* Components */
.react-read-more-read-less {
  text-decoration: underline;
}
